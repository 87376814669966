<template>
  <div class="columns">
      <div class="column is-one-third">
        <card-component
          title="1. Select a dataset, choose a normalization type and set options"
          class="tile is-child"
          :displayHelp="$options.name"
        >
          <validation-observer v-slot="{ handleSubmit }">
            <validation-provider
              v-slot="{ errors }"
              :rules= {required:true}
              name="dataset"
            >
              <dataset-select
                label="Select a dataset"
                :dataset.sync="datasetName"
                v-model="datasetName"
                :withVarname="false"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                icon="table"
                pack="fa"
                :minNumericVariable=1
                expanded
              />
            </validation-provider>
            <b-field
                label="Choose normalization type"
              >
                <b-select
                  v-model="template"
                  expanded
                  @change="resetForm()"
                >
                <option
                    v-for="(temp,index) in availableTemplate"
                    :key="index" :value="index">{{temp}}
                </option>
                </b-select>
            </b-field>
            <div v-if="template==='scaling'">
                 <b-field  label="Use steps:"><b-checkbox  :value="true" disabled>
                      center
                  </b-checkbox></b-field>
                  <b-field><b-checkbox v-model="scale_reduce">
                      scale to unit variance
                  </b-checkbox></b-field>
            </div>
            <div v-if="template==='combat'">
                <validation-provider
                  v-slot="{ errors }"
                  :rules= {required:true}
                  name="datasetbatch"
                >
                  <dataset-select
                    label="Select the batch variable"
                    :dataset.sync="datasetNameBatch"
                    :varname.sync="variableBatch"
                    v-model="variableBatch"
                    :withVarname="true"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    icon="table"
                    pack="fa"
                    expanded
                    @select="option => variableBatch=option.name"
                    :vartype="'quali'"
                    :minlevel="2"
                    :minCatVariable="1"
                    :alldistinct="'no'"
                    labeltooltip="Non-constant categorical variables whith at least two individuals per batch"
                  />
                </validation-provider>
            </div>
            <div v-if="template==='metagenomics_1'">
              <b-field  label="Use steps:"></b-field>
              <b-field><b-checkbox  :value="true" disabled >
                      offset
              </b-checkbox></b-field>
              <b-field><b-checkbox  :value="true" disabled >
                    filter
              </b-checkbox></b-field>
              <b-field><b-checkbox  :value="true" disabled >
                    normalization
              </b-checkbox></b-field>
            </div>
            <div v-if="template==='microarray'">
              <b-field  label="Use steps:"></b-field>
              <b-field><b-checkbox  :value="true" disabled >
                      max filter
              </b-checkbox></b-field>
              <b-field><b-checkbox  :value="true" disabled >
                    log2
              </b-checkbox></b-field>
              <b-field><b-checkbox  :value="true" disabled >
                    quantile normalization
              </b-checkbox></b-field>
            </div>
            <div v-if="form_field['steps'].includes(template)">
              <b-field  label="Use steps:"></b-field>
                <b-field v-if='template.startsWith("unlog")'><b-checkbox  :value="true" disabled >
                      unlog
                </b-checkbox></b-field>
                <validation-provider
                  v-slot="{ errors }"
                  :rules= {required:true}
                  name="dataset"
                >
                <b-field v-if="selectedDatasetObject.meta.normalized === 'yes'"><b-checkbox value="false" disabled>
                      filter
                  </b-checkbox></b-field>
                <b-field v-if="selectedDatasetObject.meta.normalized === 'no'"><b-checkbox v-model="has_filter" :message="errors">
                      filter
                  </b-checkbox></b-field>
                  <b-field v-if="selectedDatasetObject.meta.normalized === 'no'"
                    label="Compute scaling factor using"
                    >
                    <div class="block">
                    <b-radio
                        v-model="has_TMM"
                        native-value="TMM"
                    >
                        TMM
                    </b-radio>
                    <b-radio
                        v-model="has_TMM"
                        native-value="TMMwsp"
                    >
                        TMMwsp
                    </b-radio>
                    <b-radio
                        v-model="has_TMM"
                        native-value="none"
                    >
                        none (library size)
                    </b-radio>
                    </div>
                </b-field>
                  <b-field><b-checkbox v-model="has_log">
                      log(cpm(x) + prior.count)
                  </b-checkbox></b-field>
                </validation-provider>
            </div>
            <div v-if="template==='metagenomics_1'">
              <validation-provider
              v-slot="{ errors, valid}"
              :rules= {required:true,min_value:0}
              name="offset"
              >
                  <b-field
                  label="Offset"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  >
                      <b-input
                          v-model="offset"
                          expanded
                      ></b-input>
                  </b-field>
              </validation-provider>
            </div>
            <div v-if='template.startsWith("unlog")'>
                <b-field
                    label="Unlog base"
                    >
                    <div class="block">
                    <b-radio
                        v-model="unlog_base"
                        native-value="e"
                    >
                        e
                    </b-radio>
                    <b-radio
                        v-model="unlog_base"
                        native-value=2
                    >
                        2
                    </b-radio>
                    <b-radio
                        v-model="unlog_base"
                        native-value=10
                    >
                        10
                    </b-radio>
                    </div>
                </b-field>
                <validation-provider
                    v-slot="{ errors, valid}"
                    :rules= {required:true,min_value:0}
                    name="unlogpriorcount"
                  >
                  <b-field
                    label="Unlog prior count"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-input
                      v-model="unlog_prior_count"
                      expanded
                    ></b-input>
                  </b-field>
                </validation-provider>
            </div>
            <div v-if="form_field['filter_type'].includes(template)">
                <b-field>
                  <template #label>
                    Filter type
                      <b-tooltip type="is-primary" label="Absolute filters work on counts, while relative filters works on the percentages corresponding to the counts.">
                          <i class="fa fa-question-circle-o"></i>
                      </b-tooltip>
                  </template>
                  <div class="block">
                      <b-radio
                          v-model="filter_type"
                          native-value="absolute"
                          :disabled="!has_filter || (selectedDatasetObject.meta.normalized === 'yes' && (template === 'rnaseqcount' || template === 'metagenomics_2'))"
                      >
                          absolute
                      </b-radio>
                      <b-radio
                          v-model="filter_type"
                          ref="filter_type"
                          native-value="relative"
                          :disabled="!has_filter || (selectedDatasetObject.meta.normalized === 'yes' && (template === 'rnaseqcount' || template === 'metagenomics_2'))"
                      >
                          relative
                      </b-radio>
                  </div>
              </b-field>
            </div>
            <div v-if="form_field['filter_threshold'].includes(template)">
                <validation-provider
                  v-slot="{ errors, valid}"
                  :rules= {required:true,min_value:0,max_threshold:filter_type}
                  :customMessages = "{max_threshold: 'Max value for relative filter is 1'}"
                  name="filter_threshold"
                  >
                      <b-field
                      label="Filter threshold"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                      >
                          <b-input
                              ref="filter_threshold"
                              v-model="filter_threshold"
                              expanded
                              :disabled="!has_filter || (selectedDatasetObject.meta.normalized === 'yes' && (template === 'rnaseqcount' || template === 'metagenomics_2'))"
                          ></b-input>
                      </b-field>
                </validation-provider>
            </div>
            <div v-if="form_field['log'].includes(template)">
                <b-field
                    label="Log base"
                    >
                    <div class="block">
                    <b-radio
                        v-model="log_base"
                        native-value="e"
                        :disabled="!has_log && template !== 'logarithm'"
                    >
                        e
                    </b-radio>
                    <b-radio
                        v-model="log_base"
                        native-value=2
                        :disabled="!has_log && template !== 'logarithm'"
                    >
                        2
                    </b-radio>
                    <b-radio
                        v-model="log_base"
                        native-value=10
                        :disabled="!has_log && template !== 'logarithm'"
                    >
                        10
                    </b-radio>
                    </div>
                </b-field>
                <validation-provider
                    v-slot="{ errors, valid}"
                    :rules= {required:true,min_value:0}
                    name="priorcount"
                  >
                  <b-field
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <template #label>
                      Prior count
                        <b-tooltip type="is-primary" label="Added value to allow computation of the log when the dataset has zeros.">
                            <i class="fa fa-question-circle-o"></i>
                        </b-tooltip>
                    </template>
                    <div>
                      <b-input
                        v-model="prior_count"
                        expanded
                        :disabled="!has_log && template !== 'logarithm'"
                      ></b-input>
                    </div>
                  </b-field>
                </validation-provider>
            </div>
            <div v-if="form_field['normalization'].includes(template)">
                <b-field
                  label="Normalization"
                  >
                  <div class="block">
                      <b-radio
                          v-model="normalization"
                          native-value="CLR"
                      >
                          CLR
                      </b-radio>
                      <b-radio
                          v-model="normalization"
                          native-value="ILR"
                      >
                          ILR
                      </b-radio>
                      <b-radio v-if="template==='metagenomics_1'"
                          v-model="normalization"
                          native-value="CSS"
                      >
                          CSS
                      </b-radio>
                  </div>
              </b-field>
            </div>
            <hr />
            <div class="has-text-centered">
              <b-button rounded
                @click="handleSubmit(run)"
                class="is-primary button-shadow"
                :disabled="!hasRunableStep"
              >
                <span class="icon is-small"><i class="fa fa-search"></i></span>
                <span> Run </span>
              </b-button>
            </div>
          </validation-observer>
        </card-component>
      </div>
      <div class="column">
        <card-component title="2. Dataset" />
        <card-no-result v-if="!resultsLoaded"/>
        <card-component v-if="basicTable||graphical" >
            <b-tabs @input="displayResult">
              <b-tab-item label="Dataset" icon="table" value="dataset" />
              <b-tab-item label="Plots" icon="bar-chart" value="plots" v-if="graphical"/>
              <b-tab-item label="Info" icon="th" value="info" v-if="basicTable"/>
            </b-tabs>
        </card-component>
        <error :type='"notifications"' />
        <div v-if="resultsLoaded && objectName && currentResult==='dataset'">
            <dataset-view :dataset-name="objectName" :resetError="false" />
            <card-component>
              <r-extract-dataset :datasetName="objectName" :baseName="selectedDatasetObject.user_name"></r-extract-dataset>
            </card-component>
        </div>
        <card-component v-if="resultsLoaded && currentResult==='info'" :title="basicTable.title">
            <basic-table :data="basicTable" />
        </card-component>
        <card-component v-if="resultsLoaded && currentResult==='plots'">
          <plotly
            v-if="graphicals.length===1"
            :data="graphicals[0]"
          ></plotly>
          <plotly-list
            v-else
            :data="graphicals"
          ></plotly-list>
        </card-component>
      </div>
    </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { useErrorStore } from '@/stores/error'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
export default {
  name: 'RNormalizationStep',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'error': () => import('@/components/ui/Error.vue'),
    'dataset-view': () => import('@/components/DatasetView.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'r-extract-dataset': () => import('@/components/ranalyses/RExtractDataset.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      datasetName: null,
      selectedDatasetObject: null,
      datasetNameBatch: null,
      variableBatch: null,
      template: 'logarithm',
      log_base: 2,
      prior_count: 0.5,
      offset: 0.5,
      filter_type: 'absolute',
      filter_threshold: 0.5,
      normalization: 'CLR',
      scale_reduce: false,
      unlog_base: 2,
      unlog_prior_count: 0.5,
      has_log: true,
      has_filter: true,
      has_TMM: 'TMM',
      resultsLoaded: false,
      previewLoading: true,
      objectName: null,
      currentResult: 'dataset',
      // correspondance entre  les champs affichables et les templates
      form_field: {
        'log': ['logarithm', 'rnaseqcount', 'metagenomics_2', 'unlog_rnaseqcount', 'unlog_metagenomics_2'],
        'filter_type': ['rnaseqcount', 'metagenomics_2', 'metagenomics_1', 'unlog_rnaseqcount', 'unlog_metagenomics_2'],
        'filter_threshold': ['rnaseqcount', 'metagenomics_2', 'metagenomics_1', 'unlog_rnaseqcount',
          'unlog_metagenomics_2', 'microarray'],
        'normalization': ['metagenomics_1', 'compositional'],
        'unlog': ['unlog_rnaseqcount', 'unlog_metagenomics_2'],
        'steps': ['rnaseqcount', 'metagenomics_2', 'unlog_rnaseqcount', 'unlog_metagenomics_2']
      }
    }
  },
  setup () {
    const errorStore = useErrorStore()
    const workspaceStore = useWorkspaceStore()
    return { errorStore, workspaceStore }
  },
  mounted () {
    if (this.analysisObj !== null) {
      this.unlog_prior_count = this.analysisObj.meta.func_args['unlog_prior.count']
      this.prior_count = this.analysisObj.meta.func_args['prior.count']
    }
    extend('max_threshold', {
      getMessage: function () { return 'Max value for relative is 1' },
      validate: function (value, [filterType]) {
        return ((filterType === 'relative' && value <= 1) || (filterType === 'absolute'))
      }
    })
  },
  watch: {
    datasetName: function () {
      if (this.datasetName) {
        this.selectedDatasetObject = this.workspaceStore.datasets.filter(obj => obj.object_name === this.datasetName)[0]
      }
    }
  },
  computed: {
    hasRunableStep: function () {
      if (this.template.startsWith('unlog') || this.template === 'rnaseqcount' || this.template === 'metagenomics_2') {
        if (this.selectedDatasetObject.meta.normalized === 'yes' && !this.has_log) {
          return false
        }
        if (this.has_log || this.has_filter || this.has_TMM === 'TMM' || this.has_TMM === 'TMMwsp') {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    params: function () {
      let param = {
        'func_name': 'r_norm_dataset',
        'datasetName': this.datasetName,
        'template': this.template,
        'out_graph': true
      }
      switch (this.template) {
        case 'logarithm':
          param['log_base'] = this.log_base
          param['prior.count'] = parseFloat(this.prior_count)
          break
        case 'combat':
          param['datasetName_batches'] = this.datasetNameBatch
          param['varName_batches'] = this.variableBatch
          break
        case 'scaling':
          param['scale_reduce'] = this.scale_reduce
          break
        case 'metagenomics_1':
          param['normalization'] = this.normalization
          param['offset'] = parseFloat(this.offset)
          param['filter_type'] = this.filter_type
          param['filter_threshold'] = parseFloat(this.filter_threshold)
          break
        case 'microarray':
          param['filter_threshold'] = parseFloat(this.filter_threshold)
          break
        case 'compositional':
          param['normalization'] = this.normalization
          break
        case 'norm_quantiles':
          break
        default :
          param['has_filter'] = this.has_filter
          if (this.selectedDatasetObject.meta.normalized === 'yes') {
            param['has_filter'] = false
          }
          if (this.has_filter) {
            param['filter_type'] = this.filter_type
            param['filter_threshold'] = parseFloat(this.filter_threshold)
          }
          if (this.has_TMM === 'TMM') {
            param['has_TMM'] = true
            param['has_TMMwsp'] = false
          }
          if (this.has_TMM === 'TMMwsp') {
            param['has_TMM'] = false
            param['has_TMMwsp'] = true
          }
          if (this.has_TMM === 'none') {
            param['has_TMM'] = false
            param['has_TMMwsp'] = false
          }
          if (this.selectedDatasetObject.meta.normalized === 'yes') {
            param['has_TMM'] = false
            param['has_TMMwsp'] = false
          }
          param['has_log'] = this.has_log
          if (this.has_log) {
            param['log_base'] = this.log_base
            param['prior.count'] = parseFloat(this.prior_count)
          }
          if (this.template.startsWith('unlog')) {
            param['unlog_base'] = this.unlog_base
            param['unlog_prior.count'] = parseFloat(this.unlog_prior_count)
          }
          break
      }
      return param
    },
    availableTemplate: function () {
      let temp = {}
      if (this.selectedDatasetObject) {
        switch (this.selectedDatasetObject.meta.nature) {
          case 'rna-count':
            if (this.selectedDatasetObject.meta.logt === 'no') {
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['logarithm'] = 'Log-transformation'
                temp['combat'] = 'Correct batch effect'
                temp['rnaseqcount'] = 'Normalization based on library size (TMM, etc)'
              } else {
                if (this.selectedDatasetObject.meta.norm_factors === 'no') {
                  temp['logarithm'] = 'Log-transformation'
                }
                temp['rnaseqcount'] = 'Preprocessing for count data'
              }
            } else {
              temp['scaling'] = 'Centering and scaling to unit variance'
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['combat'] = 'Correct batch effect'
                temp['unlog_rnaseqcount'] = 'Normalization for log-transformed count data'
              }
            }
            break
          case 'metagenomics-count':
            if (this.selectedDatasetObject.meta.logt === 'no') {
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['logarithm'] = 'Log-transformation'
                temp['combat'] = 'Correct batch effect'
                temp['metagenomics_1'] = 'Normalization as compositional data'
                temp['metagenomics_2'] = 'Normalization as count data (library size)'
              } else {
                if (this.selectedDatasetObject.meta.norm_factors === 'no') {
                  temp['logarithm'] = 'Log-transformation'
                }
                temp['metagenomics_2'] = 'Preprocessing for count data'
              }
            } else {
              temp['scaling'] = 'Centering and scaling to unit variance'
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['combat'] = 'Correct batch effect'
                temp['unlog_metagenomics_2'] = 'Normalization for log-transformed count data'
              }
            }
            break
          case 'metagenomics-compo':
            if (this.selectedDatasetObject.meta.logt === 'no') {
              temp['logarithm'] = 'Log-transformation'
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['compositional'] = 'Normalization for compositional data'
              }
            } else {
              temp['scaling'] = 'Centering and scaling to unit variance'
            }
            break
          case 'metabolite-compo':
            if (this.selectedDatasetObject.meta.logt === 'no') {
              temp['logarithm'] = 'Log-transformation'
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['compositional'] = 'Normalization for compositional data'
              }
            } else {
              temp['scaling'] = 'Centering and scaling to unit variance'
            }
            break
          case 'microarray':
            if (this.selectedDatasetObject.meta.logt === 'no') {
              temp['logarithm'] = 'Log-transformation'
              temp['scaling'] = 'Centering and scaling to unit variance'
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['combat'] = 'Correct batch effect'
                temp['microarray'] = 'Microarray normalization (based on quantiles)'
              }
            } else {
              temp['scaling'] = 'Centering and scaling to unit variance'
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['combat'] = 'Correct batch effect'
              }
            }
            break
          default:
            if (this.selectedDatasetObject.meta.logt === 'no') {
              temp['logarithm'] = 'Log-transformation'
              temp['scaling'] = 'Centering and scaling to unit variance'
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['norm_quantiles'] = 'Quantile normalization'
                temp['combat'] = 'Correct batch effect'
              }
            } else {
              temp['scaling'] = 'Centering and scaling to unit variance'
              if (this.selectedDatasetObject.meta.normalized === 'no') {
                temp['norm_quantiles'] = 'Quantile normalization'
                temp['combat'] = 'Correct batch effect'
              }
            }
        }
      }
      return temp
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
    },
    resetForm: function () {
      this.has_log = true
      this.has_filter = true
      this.has_TMM = 'TMM'
    },
    beforeRun: function () {
      this.displayResult('dataset')
      this.resultsLoaded = false
      this.objectName = null
    },
    afterRun: function (data) {
      if (data.ObjectName) {
        this.objectName = data.ObjectName
      }
      if ('Messages' in data) {
        this.errorStore.setNotifications(data.Messages.data)
      }
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
